import React from 'react';
import { CardTitleIcon, DiscordIcon, LineIcon } from '../../utils/IconsUtils';
import cardGif from '../../videos/cardGif.mp4';
import { useTranslation } from 'react-i18next';

import styles from './Card.module.scss';

const Card = ({ dataKey, title, contentTitle, content, contentText, contentTextLeft, differentColor }) => {

    const { t, i18n } = useTranslation();

    const leftContentData = [
        {
            id: 1,
            title: "EXP" + " " + t('rate')
        },
        {
            id: 2,
            title: "SP" + " " +  t('rate')
        },
        {
            id: 3,
            title: t('drop_rate')
        },
        {
            id: 4,
            title: "MAX LV"
        }
    ]

    return (
        <div key={dataKey}>
            <div className={styles.titleContainer}>
                <div className={styles.titleContent}>
                    <CardTitleIcon />
                    <span className={styles.title}>
                        {title}
                    </span>
                </div>
            </div>
            <div className={styles.customizedCardContainer}>
                <div className={styles.contentContainer}>
                    <div className={styles.typeContainer}>
                        <div style={{background: differentColor ? "rgba(255, 0, 229, 0.10)" : "rgba(20, 255, 0, 0.10)"}} className={styles.typeCoxContainer}>
                            <span>{contentTitle}</span>
                        </div>
                    </div>
                    <div className={styles.typeDetailContainer}>
                        <div className={styles.typeLeftContent}>
                            {
                                leftContentData.map(val => (
                                    <span key={val.id + "-"}>{val.title}</span>
                                ))
                            }
                        </div>
                        <div className={styles.typeRightContent}>
                            {
                                content.map((val, idx) => (
                                    <div key={idx + "*"} className={styles.typeBoxContainer}>
                                        <span>{val.title}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={styles.hiddenLogo}>
                        <div className={styles.hiddenLogoCont}>
                            {/* <DiscordIcon color={"#fff"} /> */}
                            <a style={{textDecoration: "none"}} href="https://discord.com/invite/ecsro">
                                <video style={{width: "100%", height: "100%"}} autoPlay muted loop id="myVideo2">
                                    <source src={cardGif} type="video/mp4" />
                                </video>
                            </a>
                        </div>
                    </div>
                    <LineIcon />
                    <div className={styles.hiddenTextContainer}>
                        <span>
                            {t('join_our_discord')}
                        </span>
                        <div className={styles.joinButtonContainer}>
                            <a style={{textDecoration: "none"}} href="https://discord.com/invite/ecsro">
                                <button>
                                    <span>{t('join')}</span>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div style={{textAlign: contentTextLeft ? "left": "center"}} className={styles.textsContainer}>
                        {
                            contentText.map((val, idx) => (
                                <span key={idx + "="}>
                                    {val}
                                </span>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;