import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                in_the_development_process: "IN THE DEVELOPMENT PROCESS",
                private: "PRIVATE",
                accounts_for_sale_only: "Accounts for sale only.",
                game_version_is: "Game version is 1.188",
                there_are_only_chinese: "There are only Chinese characters and hybird (old-modern) systems in game.",
                classic: "CLASSIC",
                free_to_play: "FREE TO PLAY",
                soon: "Soon...",
                fembria: "FEMBRIA",
                rate: "RATE",
                drop_rate: "DROP RATE",
                copyrigt: "Copyrigt © 2024 ecsro.net",
                all_rights_reserved: "All rights reserved",
                join_our_discord: "Join our Discord for more information",
                join: "Join"
                
            }
        },
        tr: {
            translation: {
                in_the_development_process: "GELİŞİM SÜRECİNDE",
                private: "ÖZEL",
                accounts_for_sale_only: "Hesaplar yalnızca satılıktır.",
                game_version_is: "Oyunun sürümü 1.188",
                there_are_only_chinese: "Oyunda sadece Çince karakterler ve hibrit (eski-modern) sistemler mevcut.",
                classic: "KLASİK",
                free_to_play: "OYNAMASI ÜCRETSİZ",
                soon: "Yakında...",
                fembria: "FEMBRİA",
                rate: "ORANI",
                drop_rate: "DÜŞME ORANI",
                copyrigt: "Telif hakkı © 2024 ecsro.net",
                all_rights_reserved: "Her hakkı saklıdır",
                join_our_discord: "Daha fazla bilgi için Discord'umuza katılın",
                join: "Katıl"
                
            }
        },
        fr: {
            translation: {
                in_the_development_process: "DANS LE PROCESSUS DE DÉVELOPPEMENT",
                private: "PRIVÉE",
                accounts_for_sale_only: "Comptes à vendre uniquement.",
                game_version_is: "La version du jeu est 1.188",
                there_are_only_chinese: "Il n'y a que des caractères chinois et des systèmes hybrides (anciens-modernes) dans le jeu.",
                classic: "CLASSIQUE",
                free_to_play: "LIBRE DE JOUER",
                soon: "Bientôt...",
                fembria: "FEMBRIE",
                rate: "TAUX",
                drop_rate: "LE TAUX D'ABANDON",
                copyrigt: "Droits d'auteur © 2024 ecsro.net",
                all_rights_reserved: "Tous droits réservés",
                join_our_discord: "Rejoignez notre Discord pour plus d'informations",
                join: "Rejoindre"
                
            }
        },
        de: {
            translation: {
                in_the_development_process: "IM ENTWICKLUNGSPROZESS",
                private: "PRIVAT",
                accounts_for_sale_only: "Nur zum Verkauf stehende Konten.",
                game_version_is: "Die Spielversion ist 1.188",
                there_are_only_chinese: "Im Spiel gibt es nur chinesische Schriftzeichen und Hybird-Systeme (altmodern).",
                classic: "KLASSISCH",
                free_to_play: "KOSTENLOS SPIELEN",
                soon: "Bald...",
                fembria: "FEMBRİA",
                rate: "RATE",
                drop_rate: "FALLGESCHWINDIGKEIT",
                copyrigt: "Urheberrecht © 2024 ecsro.net",
                all_rights_reserved: "Alle Rechte vorbehalten",
                join_our_discord: "Treten Sie unserem Discord bei, um weitere Informationen zu erhalten",
                join: "Verbinden"
                
            }
        }
    }
});

export default i18n;