import React, { useEffect } from 'react';
import HexagonBackground from '../../components/hexagonBackground/HexagonBackground';
import WomanImg from '../../images/woman.png';
import MenImg from '../../images/man.png';
import logo1 from '../../images/logo1.png';
import logo2 from '../../images/logo2.png';
import logo3 from '../../images/logo3.jpeg';
import logo4 from '../../images/logo4.png';
import logo from '../../images/logo.png';

import VideoCard from '../../components/videoCard/VideoCard';
import BackgroundVideo from '../../components/backgroundVideo/BackgroundVideo';
import { StarIcon } from '../../utils/IconsUtils';
import Card from '../../components/customizedCard/Card.js';
import { useTranslation } from 'react-i18next';

import styles from './HomePage.module.scss';

const HomePage = () => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        var lang = JSON.parse(localStorage.getItem("lang"));
        i18n.changeLanguage(lang ?? "en");
    },[])
    
    const customizedCardData = [
        {
            id: 1,
            title: "VIP",
            contentTitle: t('private'),
            content: [
                {
                    id: 1,
                    title: "x15"
                },
                {
                    id: 1,
                    title: "x15"
                },
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "100"
                },
            ],
            contentText: [
                t('accounts_for_sale_only'),
                t('game_version_is'),
                t('there_are_only_chinese'),
            ],
            contentTextLeft: true
        },
        {
            id: 2,
            title: t('classic'),
            contentTitle: t('free_to_play'),
            content: [
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "70"
                },
            ],
            contentText: [
                t('soon'),
            ],
            contentTextLeft: false
        },
        {
            id: 3,
            title: t('fembria'),
            contentTitle: t('free_to_play'),
            content: [
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "x10"
                },
                {
                    id: 1,
                    title: "90"
                },
            ],
            contentText: [
                t('soon'),
            ],
            contentTextLeft: false
        }
    ]
    return (
        <div className={styles.homePageContainer}>
            <div className={styles.sky}>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
                <div className={styles.star}></div>
            </div>
            <HexagonBackground />
            <BackgroundVideo />
            <div className={styles.scrollContainer}>
                <div className={styles.scrollText}>
                    <StarIcon style={{marginBottom: "-11px"}} />
                    <span>{t('in_the_development_process')}</span>
                    <StarIcon style={{marginBottom: "-11px"}} />
                    <span>{t('in_the_development_process')}</span>
                    <StarIcon style={{marginBottom: "-11px"}} />
                    <span>{t('in_the_development_process')}</span>
                    <StarIcon style={{marginBottom: "-11px"}} />
                    <span>{t('in_the_development_process')}</span>
                    <StarIcon style={{marginBottom: "-11px"}} />
                    <span>{t('in_the_development_process')}</span>
                    <StarIcon style={{marginBottom: "-11px"}} />
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.topContainer}>
                    <VideoCard />
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.womanImgCont}>
                        <img src={WomanImg} alt="woman" width="100%" height="100%" />
                    </div>
                    <div className={styles.cardMainContainer}>
                        {
                            customizedCardData.map((val, idx) => (
                                <Card 
                                    dataKey={idx} 
                                    title={val.title} 
                                    contentTitle={val.contentTitle} 
                                    content={val.content} 
                                    contentText={val.contentText} 
                                    contentTextLeft={val.contentTextLeft} 
                                    differentColor={val.id === 1 ? true : false} 
                                />
                            ))
                        }
                    </div>
                    {/* <CustomizedCard />
                    <CustomizedCard /> */}
                    <div className={styles.menImgCont}>
                        <img src={MenImg} alt="woman" width="100%" height="100%" />
                    </div>
                </div>
                <div className={styles.logoContainer}>
                    <img style={{width: "146px", height: "40px"}} src={logo} alt="logo1" />
                    {/* <img style={{width: "146px", height: "40px"}} src={logo2} alt="logo1" /> */}
                    {/* <img style={{width: "146px", height: "40px"}} src={logo3} alt="logo1" /> */}
                    {/* <img style={{width: "146px", height: "40px"}} src={logo4} alt="logo1" /> */}
                </div>
                <div className={styles.footerContainer}>
                    <span>{t('copyrigt')}</span>
                    <span>{t('all_rights_reserved')}</span>
                </div>
            </div>
        </div>
    )
}

export default HomePage