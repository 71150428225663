import React from 'react';
import backgroundVideo from '../../videos/backgroundVideo.mp4';

import styles from './BackgroundVideo.module.scss';

const BackgroundVideo = () => {
    return (
        <div className={styles.backgroundVideoCont}>
            <video style={{width: "100%", height: "100%"}} autoPlay muted loop id="myVideo2">
                <source src={backgroundVideo} type="video/mp4" />
            </video>
        </div>
    )
}

export default BackgroundVideo