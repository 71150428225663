import React from 'react'
import HomePage from './pages/homePage/HomePage'
import './App.scss';
const App = () => {
  return (
    <div>
      <HomePage />
    </div>
  )
}

export default App