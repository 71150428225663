import React from 'react';

import styles from './HexagonBackground.module.scss';

const HexagonBackground = () => {
    return (
        <div className={styles.hexagonContainer}>
            {
                Array.from(Array(38).keys()).map((value, index) => (
                    <div key={index} className={styles.row}>
                        {
                            Array.from(Array(25).keys()).map((val, idx) => (
                                <div key={idx} className={styles.hexagon}>
                                    <div className={styles.hex}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                    
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default HexagonBackground