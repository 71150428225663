import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import video from '../../videos/1.mp4';
import firstGirl from '../../images/girl1.png';
import secondGirl from '../../images/girl2.png';
import turkeyIcon from '../../images/turkey.png';
import germanyIcon from '../../images/germany.png';
import frenchIcon from '../../images/french.png';
import usdIcon from '../../images/usd.png';
import { motion } from "framer-motion";
import WomanImg from '../../images/woman.png';
import { useTranslation  } from 'react-i18next';

import styles from './VideoCard.module.scss';

const VideoCard = () => {

    const { t, i18n } = useTranslation();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [langBackupDatas, setLangBackupDatas] = useState([]);
    const [selectedLang, setSelectedLang] = useState([
        {
            id: "en",
            title: "English",
            url: usdIcon,
            selected: true
        }
    ])
    
    const [langDatas, setLangDatas] = useState([
        {
            id: "tr",
            title: "Turkish",
            url: turkeyIcon,
            selected: false
        },
        {
            id: "en",
            title: "English",
            url: usdIcon,
            selected: true
        },
        {
            id: "fr",
            title: "French",
            url: frenchIcon,
            selected: false
        },
        {
            id: "de",
            title: "German",
            url: germanyIcon,
            selected: false
        }
    ])

    useEffect(() => {
        var lang = JSON.parse(localStorage.getItem("lang"));
        setLangBackupDatas(langDatas);

        if(lang !== null){
            setLangDatas(langDatas.map(val => {
                return {...val, selected: val.id === lang ? true : false}
            }))
            setSelectedLang(langDatas.filter(item => item.id === lang))
        }
        
    },[])

    const clickMenuItem = (id) => {
        i18n.changeLanguage(id);
        localStorage.setItem('lang', JSON.stringify(id));
        setSelectedLang(langBackupDatas.filter(item => item.id === id))
        setLangDatas(langDatas.map(val => {
            return {...val, selected: val.id === id ? true : false}
        }))
        setIsOpenMenu(false);
    }

    return (
        <>
            <motion.div 
                initial={{ y: 350 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeOut", duration: 1.2 }}
                className={styles.videoContainer}
            >
                <a style={{textDecoration: "none", width: "100%", height: "100%"}} href="https://discord.com/invite/ecsro">
                    <video style={{width: "100%", height: "100%"}} autoPlay muted loop id="myVideo">
                        <source src={video} type="video/mp4" />
                    </video>
                </a>
                <div className={styles.imagesContainer}>
                    <img src={firstGirl} alt="" />
                    <img src={secondGirl} alt="" />
                </div>
            </motion.div>
            <div className={styles.langContainer}>
                <div onClick={() => setIsOpenMenu(!isOpenMenu)} className={styles.langButton}>
                    <span>
                        {selectedLang[0].title}
                    </span>
                    <img src={selectedLang[0].url} alt="" />
                </div>
                <div style={{height: isOpenMenu ? "100%" : "0px", transition: "0.1s"}} className={styles.langMenu}>
                    {
                        langDatas.map((val, idx) => {
                            if(!val.selected){
                                return(
                                    <div key={idx} onClick={() => clickMenuItem(val.id)} style={{height: isOpenMenu ? "100%" : "0px", border: isOpenMenu ? "1px solid rgba(252, 252, 252, 0.2)" : "none", transition: "0.1s"}} className={styles.langMenuItem}>
                                        <span style={{fontSize: isOpenMenu ? "14px" : "0px", transition: "0.1s"}}>
                                            {val.title}
                                        </span>
                                        <img style={{height: isOpenMenu ? "100%" : "0px", transition: "0.1s"}} src={val.url} alt="" />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default VideoCard